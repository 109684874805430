import React from "react";
import '/src/assets/css/Common/benefit-info.css'

const BenefitInfo = ({img, imgAlt, title, description, svgIcon}) => {

    return (
        <section id='benefit-info'>
            <div className="d-flex flex-row mb-5">
                <div className="benefit-icon">
                    {svgIcon ? svgIcon : <img src={img} alt={imgAlt} className='img-fluid' loading="lazy"/>}
                </div>
                <div className="info-content d-flex flex-column">
                    <h4 className='main-color mb-3'>{title}</h4>
                    <p className='main-color benefit-description'>{description}</p>
                </div>
            </div>
        </section>
    )
}

export default BenefitInfo