import React from "react";
import "/src/assets/css/TransferDomain/get-in-touch.css"
import getInTouchImg from '/src/assets/images/transfer-domain/get-in-touch.png'
import {Link} from "gatsby";

const GetInTouch = () => {
    return (
        <section id="get-in-touch" className='main-blue-bg'>
            <div className="container">
                <div className="row position-relative">
                    <div className="col-lg-9 col-12">

                        <div className="d-flex flex-lg-row align-items-center flex-column">
                            <div className="pr-4 text-lg-left text-center">
                                <h3 className='h3-title text-white'>Confuz?</h3>
                                <p className='text-white'>Cu siguranta un membru al echipei noastre de suport te va
                                    ajuta sa
                                    clarifici orice aspect al unui transfer de domeniu.</p>
                            </div>

                            <Link to='/contact'
                                  className='sm-white-btn-outline call-us-btn mt-lg-0 mt-4'>Hai
                                sa vorbim</Link>
                        </div>


                    </div>

                    <img src={getInTouchImg} alt="Get in touch" className='call-us-img img-fluid d-lg-inline d-none' loading="lazy"/>

                </div>
            </div>
        </section>
    )
}

export default GetInTouch