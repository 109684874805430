import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import TransferDomainIntro from "../components/TransferDomain/TransferDomainIntro";
import SimpleFAQS from "../components/Common/SimpleFAQS";
import TransferSteps from "../components/TransferDomain/TransferSteps";
import WhyTransferToUs from "../components/TransferDomain/WhyTransferToUs";
import GetInTouch from "../components/ColocationServers/GetInTouch";
import SEO from "../components/App/SEO";


const faqs = [
    {
        title: 'Cât timp este necesar pentru finalizare transfer domenii?',
        text1: 'În funcție de registrarul curent, un proces de transfer domenii poate dura între 24 de ore și 7 zile.',
    },
    {
        title: 'Care sunt costurile transferului de domenii?',
        text1: 'Costurile de transfer domenii variază în funcție de extensia acestora, deoarece în momentul transferului va fi necesară (pentru anumite TLD-uri) și reînnoirea acestora pentru o perioadă minimă de 1 an. Poți contacta oricând echipa de suport SiteBunker.ro pentru întrebări legate de costuri.',
    }
]

const DomainTransfer = () => {
    return (
        <Layout>

            {/*SEO*/}
            <SEO title="Transfer domenii site la SiteBunker.ro. Solicită detalii aici!"
                 description="Acum poți transfera la SiteBunker.ro oricâte domenii site dorești, într-un mod rapid, facil și sigur. Contactează echipa suport și cere detalii complete."
            />

            {/*NavBar*/}
            <Navbar/>

            {/*Intro*/}
            <TransferDomainIntro/>

            {/*Transfer Steps*/}
            <TransferSteps/>

            {/*Why Transfer To US*/}
            <WhyTransferToUs/>

            {/*Get in touch*/}
            <GetInTouch/>

            {/*Transfer FAQS*/}
            <SimpleFAQS title='Îți stăm la dispoziție pentru orice întrebare'
                        subTitle='Citește mai jos răspunsurile pentru cele mai frecvente întrebări legate de transfer domenii.'
                        questionsData={faqs}/>

            {/*Footer*/}
            <Footer/>
        </Layout>
    )
}

export default DomainTransfer