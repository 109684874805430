import React from "react";
import domainImg from '/src/assets/images/transfer-domain/domain-search.png'
import rocketImg from '/src/assets/images/transfer-domain/rocket.png'
import searchRevertImg from '/src/assets/images/transfer-domain/search-revert.png'
import oneImg from '/src/assets/images/register-domains/1.png'
import twoImg from '/src/assets/images/register-domains/2.png'
import threeImg from '/src/assets/images/register-domains/3.png'

import '/src/assets/css/TransferDomain/transfer-steps.css'

const TransferSteps = () => {
    return (
        <section id='transfer-steps'>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3 className='h3-title main-color'>Transfer domenii în 3 pași</h3>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 step-one-content">
                        <img src={domainImg} alt="Cauta domenii" className="img-fluid step-one-illustration"/>
                        <div className="content-box">
                            <img src={oneImg} className='step-img mb-3' alt="Unu" loading="lazy"/>
                            <p className='main-color'>Contactează registrarul curent pentru a obține codul EPP solicitat
                                în cazul oricărui transfer de domeniu.</p>
                        </div>

                    </div>
                    <div className="col-lg-4 col-md-6 col-12 step-two-content">
                        <img src={rocketImg} alt="Transfer" className="img-fluid step-two-illustration"/>

                        <div className="content-box">
                            <img src={twoImg} className='step-img mb-3' alt="Doi" loading="lazy"/>
                            <p className='main-color'>Tastează numele domeniului tău în secțiunea de mai sus și introdu
                                codul EPP furnizat de către vechiul registrar.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 step-three-content">
                        <img src={searchRevertImg} alt="Cauta domenii" className="img-fluid step-three-illustration"/>

                        <div className="content-box">
                            <img src={threeImg} className='step-img mb-3' alt="Trei" loading="lazy"/>
                            <p className='main-color'>Autorizează transferul prin intermediul e-mailului primit. Nu uita
                                să verifici inclusiv folderul SPAM.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TransferSteps