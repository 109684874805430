import React from "react";
import '/src/assets/css/TransferDomain/why-transfer-to-us.css'
import BenefitsWithIconList from "../Common/BenefitsWithIconList";

// Img
import transferImg from '/src/assets/images/transfer-domain/transfer.png'

// SVG
import SvgFile from "/src/assets/svgComponents/file-text.svg"
import SvgChat from "/src/assets/svgComponents/chat.svg"
import SvgShield from "/src/assets/svgComponents/shild.svg"
import SvgTime from "/src/assets/svgComponents/time.svg"
import SvgFolder from "/src/assets/svgComponents/folder.svg"
import SvgMoney from "/src/assets/svgComponents/money.svg"


const advantagesList = [
    {
        title: 'Economisești timp',
        svgImg: <SvgTime/>,
        description: 'Salvează timp pentru ceea ce contează, administrând din același loc atât domeniul, cât și hostingul, certificatele SSL și multe altele.'
    },
    {
        title: 'Costuri transparente',
        svgImg: <SvgMoney/>,
        description: 'Poți transfera orice domeniu la SiteBunker.ro fără niciun cost ascuns.'
    },
    {
        title: 'Administrare facilă',
        svgImg: <SvgFolder/>,
        description: 'Din sistemul de management oferit de SiteBunker.ro poți administra facil și oricând domeniile, dar și celelalte resurse de hosting achiziționate.'
    },
    {
        title: 'Suport asigurat 24/7',
        svgImg: <SvgChat/>,
        description: 'Echipa de suport SiteBunker.ro este pregătită să te asiste în orice provocare tehnică, indiferent de oră sau zi.'
    },
    {
        title: 'Transfer domenii sigur',
        svgImg: <SvgShield/>,
        imgAlt: 'Transfer domenii sigur',
        description: 'Transferul domeniului tău este realizat fără niciun risc, într-un proces complet securizat și monitorizat de către echipa noastră.'
    },
    {
        title: 'Prelungire valabilitate',
        svgImg: <SvgTime/>,
        description: 'Nu uita că la finalizarea transferului va fi prelungită pentru încă 1 an valabilitatea domeniului transferat la SiteBunker.ro, excepție făcând domeniile .ro și alte tld-uri speciale.'
    },
    {
        title: 'Ai o solicitare suplimentară?',
        svgImg: <SvgFile/>,
        description: 'Echipa de suport SiteBunker.ro este pregătită să te asiste în orice provocare tehnică, de Luni până Duminică, la orice oră din zi. '
    }
]

const WhyTransferToUs = () => {
    return (
        <section id='why-transfer-to-us' className='grey-bg'>
            <div className="container">
                <div className="row position-relative">
                    <div className="col-12 text-center mb-5">
                        <h3 className='h3-title main-color mb-0'>Ai zeci de motive să transferi domeniul tău la
                            SiteBunker.ro</h3>
                        <p>Bucură-te de eficiență maximă și securitate sporită.</p>
                    </div>

                    <div className="col-lg-7 col-12">
                        <p>Economisește timp cu SiteBunker.ro, administrând atât domeniul, cât și pachetul de hosting
                            preferat dintr-un singur loc. Alege să te bucuri de profesionalism și servicii complete de
                            găzduire, cu suport 24/7 inclus. Mai mult decât atât, află că la SiteBunker.ro ai migrarea
                            asigurată și 30 de zile gratuite în care poți testa beneficiile.</p>
                    </div>

                    <img src={transferImg} className="transfer-img img-fluid" alt="Transfer" loading="lazy"/>

                </div>
            </div>

            {/*Advantages*/}
            <BenefitsWithIconList dataList={advantagesList} isGreyBackground={true}/>
        </section>
    )
}

export default WhyTransferToUs