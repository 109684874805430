import React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";

import '/src/assets/css/Common/simple-faqs.css'

const SimpleFAQS = ({title, subTitle, questionsData}) => {
    return (
        <section id='simple-faqs'>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center mb-5">
                        <h3 className='main-color h3-title mb-0'>{title}</h3>
                        {subTitle ? <p>{subTitle}</p> : ''}
                    </div>

                    <div className="col-12">
                        <div className="faq-accordion">
                            <Accordion>
                                {questionsData.map(question => (
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                {question.title}
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            {question.text1 ? <p>{question.text1}</p> : ''}
                                            {question.text2 ? <p>{question.text2}</p> : ''}
                                            {question.text3 ? <p>{question.text3}</p> : ''}
                                            {question.text4 ? <p>{question.text4}</p> : ''}
                                            {question.text5 ? <p>{question.text5}</p> : ''}
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                ))}

                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SimpleFAQS